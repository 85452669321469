/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiResources } from "@shared/helpers";
import { IMedicationAsync, IResource } from "@shared/models";
import { Observable } from "rxjs";
//import { AppData } from "@shared/services";


@Injectable()
export class ResourceService {

    constructor(private readonly http: HttpClient) {
    }

    private fetch(apiEndPoint: string, auth?: boolean, request?: any): Observable<Array<IResource>> {
        let headers = new HttpHeaders();
        headers = headers.append("Auth", auth ? "True" : "False");
        var data = this.http.get<Array<IResource>>(apiEndPoint, { headers: headers, params: request })
        return data;
    }

    private fetchWithLocation(apiEndPoint: string, request?: any): Observable<Array<IResource>> {
        let headers = new HttpHeaders();
        headers = headers.append("Auth", "True");
        return this.http.get<Array<IResource>>(apiEndPoint, { headers: headers, params: request });
    }

    appointments(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.appointments), true, { searchParam: searchParam });
    }

    countries() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.countries));
    }

    insuranceTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchInsuranceTypes));
    }

    howDidYouKnow() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.howDidYouKnow));
    }

    education() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.education));
    }

    occupation() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.occupation));
    }

    outPatientLegends() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.outPatientLegends));
    }

    widgetTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.widgetTypes));
    }

    widgets(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.widgets), true, { searchParam: searchParam });
    }

    specializations() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.specializations), true);
    }
    locationSpecializations(locationId?: number, encryptedProviderId?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.locationSpecializations), true, { locationId: locationId, encryptedProviderId: encryptedProviderId });
    }
    services() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.services), true);
    }

    department() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.department), true);
    }

    pharmaDepartment() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.pharmaDepartment), true);
    }

    providerDepartment() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.providerDepartment), true);
    }

    ScanPatients(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.ScanPatients), true, { searchParam: searchParam });
    }
    roleAccounts(roleId, searchParam: string) {//
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.roleAccounts), true, { roleId: roleId, searchParam: searchParam });
    }
    patients(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.patients), true, { searchParam: searchParam });
    }
    babyPatients(searchParam: string, isBabyRegistration) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.patients), true, { searchParam: searchParam, isBabyRegistration: isBabyRegistration });
    }
    patientsAll(patientId?:number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.patients), true, { patientId: patientId });
    }

    outPatients() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.outPatients), true, { searchParam: "" });
    }

    departments(providerId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.departments), true, { providerId: providerId });
    }

    surgeryTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.surgeryTypes), true);
    }

    provider(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.provider), true, { searchParam: searchParam });
    }

    medicationAsync(searchParam: string) {
        let headers = new HttpHeaders();
        headers = headers.append("Auth", "True");
        return this.http.get<Array<IMedicationAsync>>(ApiResources.getURI(ApiResources.progressReport.base, ApiResources.progressReport.medicationsAsync), { headers: headers, params: { searchParam: searchParam } });
    }

    labsAsync(searchParam: string) {
        let headers = new HttpHeaders();
        headers = headers.append("Auth", "True");
        return this.http.get<Array<any>>(ApiResources.getURI(ApiResources.progressReportLab.base, ApiResources.progressReportLab.labsAsync), { headers: headers, params: { searchParam: searchParam } });
    }

    newLabsAsync(term: string, chargeCategoryId: number) {

        return this.fetch(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchlabsChargeofChargeCategory), true, { term: term, chargeCategoryId: chargeCategoryId });

    }

    providersWithInPatientCharges(searchParam: string, isAdmission: boolean) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.providerWithCharges), true, { searchParam: searchParam, isAdmission });
    }


    relationships() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.relationships), true);
    }

    sameDaySessionAppointments(locationId?: number, providerId?: number, specializationId?: number, date?: string, consultationTypeId?: number, patientId?: number, visitTypeId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.sameDaySessionAppointments), true, { locationId: locationId, providerId: providerId, specializationId: specializationId, date: date, consultationTypeId: consultationTypeId, patientId: patientId, visitTypeId: visitTypeId });
    }

    insuranceCompanies() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.insuranceCompanies), true);
    }

    tpaCompanies() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.tpaCompanies), true);
    }

    defaultChargeCategory() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.defaultChargeCathgory), true);
    }

    labOrders() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.labOrder), true);
    }

    labOrder(query?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.labOrders), true, { providerId: query });
    }

    radiologies() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.radiology), true);
    }

    providerLocations(providerId: number, specializationId?: number, locationId?: number) {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.providerLocations), { providerId: providerId, specializationId: specializationId, locationId: locationId });
    }

    providers(departmentId?: number) {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.providers), { departmentId: departmentId });
    }
    providerOnly(providerId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.providerOnly), true, { providerId: providerId });
    }

    providerForSchdule(providerId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.providerSchdule), true, { providerId: providerId });
    }

    specializationForSchdule() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.specializationForProviderAvialability), true);
    }
    assignees() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchAdminSuperAdmin), true);
    }

    lookups(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.lookups), true, { searchParam: searchParam });
    }

    lookupValues(searchParam: string, lookupId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.lookupValues), true, { searchParam: searchParam, lookupId: lookupId });
    }

    documentCategories() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.documentCategories), true);
    }

    drugs(query: string, searchValue: string, medicineSearchType: string, providerId?: number) {
        return medicineSearchType === "Homeopathy" ? this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.homeopathyDrugs), true, { searchParam: query, searchValue: searchValue }) : this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.drugs), true, { searchParam: query, searchValue: searchValue, medicineSearchType: medicineSearchType, providerId: providerId });
    }

    generalAdvice(query?: number, encryptedAppointmentId?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.generalAdvice), true, { providerId: query, encryptedAppointmentId: encryptedAppointmentId });
    }

    icdCodes(query?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.icdCode), true, { providerId: query });
    }

    roles() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.role));
    }

    usersByRole(roleId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.usersByRole), true, { roleId: roleId });
    }

    rolesWithOutPatient() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.rolesWithOutPatient));
    }

    logTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.logType));
    }

    fetchAdminSuperAdminUsers() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchAdminSuperAdmin), true);
    }

    fetchUsers(roleId: number, searchParam?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchUsers),
            true,
            { roleId: roleId, searchParam: searchParam });
    }

    fetchLocationUsers(roleId: number, locationId: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchLocationRoleUsers), true, { roleId: roleId, locationId: locationId });
    }

    fetchNurses() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchNurses),
            true, {});
    }

    fetchAllUsers() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchAllUsers), true);
    }

    fetchGeneralWardNurses() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchGeneralWardNurses), true);
    }

    fetchShifts(includeTimes?: boolean) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchShifts), true, { includeTimes: includeTimes });
    }

    fetchSlots(shiftId: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchSlots), true, { shiftId: shiftId });
    }

    fetchPatientInsurances(admissionId: number, appointmentId: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.patientInsurances), true, { admissionId: admissionId, appointmentId: appointmentId });
    }

    practiceLocations(countryId: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.practiceLocations), true, { countryId: countryId });
    }

    languages() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.languages), true);
    }

    questions() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.questions), true);
    }

    users() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchUsersOnly), true);
    }

    patient(patientId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchPatient), true, { patientId: patientId });
    }

    patientsWithUmrAndMobile(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.patientsWithUmrAndMobile), true, { searchParam: searchParam });
    }

    callCenterPatientsWithUmrAndMobile(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.callCenterPatientsWithUmrAndMobile), true, { searchParam: searchParam });
    }

    pharmacyCategories() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.pharmacyCategories), true);
    }

    pharmacyCompanies() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.pharmacyCompanies), true);
    }

    pharmacyProducts(id?: number, searchTerm: string = null) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.pharmacyProducts), true, { categoryId: id, searchTerm: searchTerm });
    }

    pharmacyProduct(optionalText1: string = null, searchTerm: string = null) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.pharmacyProduct), true, { categoryName: optionalText1, searchTerm: searchTerm });
    }

    problemList(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.problemList), true, { searchParam: searchParam });
    }

    instructions() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.instructions), true);
    }

    familyMembers(appointmentId: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.familyMembers), true, { appointmentId: appointmentId });
    }

    radiologyOrders(query?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.radiologyOrders), true, { providerId: query });
    }

    receiptReports(id: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.receiptReports), true, { roleId: id });
    }

    floors() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.floors), true);
    }
    allFloors(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.allFloors), true, { locationId: locationId });
    }
    wards(floorId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.wards), true, { floorId: floorId });
    }
    allWards(floorId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.allWards), true, { floorId: floorId });
    }
    rooms(wardId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.rooms), true, { wardId: wardId });
    }
    allRooms(wardId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.allRooms), true, { wardId: wardId });
    }
    allNonTransitRooms(wardId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.allNonTransitRooms), true, { wardId: wardId });
    }
    beds(roomId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.beds), true, { roomId: roomId });
    }
    allBeds(roomId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.allBeds), true, { roomId: roomId });
    }

    bedStatus() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.bedStatus), true);
    }

    allScanMachines(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.allScanMachines), true, { locationId: locationId });
    }

    allScanTests() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.allScanTests), true);
    }

    scanMachineFilterMachines(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.scanMachineFilterMachines), true, { locationId: locationId });
    }

    scanMachineFilterTests(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.scanMachineFilterTests), true, { locationId: locationId });
    }

    allLocationScanTests(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.allLocationScanTests), true, { locationId: locationId });
    }

    encounterLookups(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.encounterLookups), true, { searchParam: searchParam });
    }

    encounterLookupValues(searchParam: string, lookupId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.encounterLookupValues), true, { searchParam: searchParam, lookupId: lookupId });
    }

    category() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.pharmacyProducts), true);
    }

    getAllAccountsExpectPassedRoleId(id: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.allAccountWithoutCurrentRoleId), true, { roleId: id })
    }

    getAllPotential() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchPotentialTypes), true)
    }
    getAllSurgeryTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchSurgeryTypes), true)
    }
    patientDoctorOnly(id: number) {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.patientDoctorsOnly), { patientId: id });
    }

    patientAdmissionDoctorOnly(id: number) {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.patientAdmissionDoctorOnly), { patientId: id });
    }

    patientAppointmentDoctorOnly(id: number) {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.patientAppointmentDoctorOnly), { patientId: id });
    }

    pharmacyLogTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.pharmacyLogType), true);
    }

    inventoryLogTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.inventoryLogType), true);
    }

    labLogTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.labLogType), true);
    }

    scanLogTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.scanLogType), true);
    }

    dischargeStatuses() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.dischargeStatus), true);
    }

    labName() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.labName), true);
    }

    providerNames() {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.providername));
    }


    payType(isSalucro?: boolean, isReceipt?: boolean) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.payType), false, { isSalucro: isSalucro, isReceipt: isReceipt });
    }

    allPayTypes(onlyActive: boolean) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.allPayTypes), false, { onlyActive: onlyActive });
    }

    packages() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.packages), true)
    }

    demandProducts() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.demandProducts), true)
    }

    images(id: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchImages), true, { id: id });
    }

    states(id: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.states), true, { id: id });
    }

    cities(id: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.cities), true, { id: id });
    }

    retailStoreNames() {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.retailStore));
    }

    warehouseNames() {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.wareHouse));
    }

    inventoryWarehouseNames() {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.inventoryWareHouse));
    }

    mealType() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.mealType));
    }

    providerPracticeLocation(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.providerPracticeLocation), true, { locationId: locationId });
    }

    admissionProviders(departmentId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.admissionProviders), true, { departmentId: departmentId });
    }

    problems() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.problems), true);
    }

    goals(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.goals), true, { problemIds: searchParam });
    }

    objectives(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.objectives), true, { goalIds: searchParam });
    }

    interventions(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.interventions), true, { objectiveIds: searchParam });
    }

    locations(includeChargeModuleTemplates = false) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.locations), true, { includeChargeModuleTemplates });
    }

    hwcPatients() {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.hwcPatient));
    }

    salutation() {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.salutation));
    }

    allHWCPatients() {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.allHWCPatients));
    }

    widgetCountTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.widgetCountTypes));
    }

    appointmentTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.appointmentTypes));
    }

    referredByTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.referredByNames));
    }

    referredDoctors() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.referredDoctors));
    }

    labDepartments() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.labDepartment));
    }

    labMasters(labname?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.labMaster), true, { labName: labname });
    }

    labvacutainers() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.labVacutainer));
    }

    labExternalAgencies() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.labExternalAgencies));
    }

    labActiveExternalAgencies() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.labActiveExternalAgencies));
    }

    scanMachine() {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.scanMachines))
    }

    scanMachineMaster(locationId?: number) {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.scanMachineMaster), { locationId: locationId });
    }

    scanMachineTest(scanMachineMasterId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchScanMachineTest), true, { scanMachineMasterId: scanMachineMasterId });
    }

    scanClassification(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.scanClassification), true, { locationId: locationId });
    }

    getAncNumber(patientId: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getAncNumber), true, { patientId: patientId });
    }

    getPatientBMI(patientId: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getPatientBMI), true, { patientId: patientId });
    }

    getCounsellingProvider(patientId: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getCounsellingDoctor), true, { patientId: patientId });
    }

    scanSubClassification(classificationId?: number, isActive?: boolean) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.scanSubClassification), true, { classificationId: classificationId, isActive: isActive });
    }

    scanSubClassificationTests(locationId?: number, classificationId?: number, subClassificationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.scanSubClassificationTests), true, { locationId: locationId, classificationId: classificationId, subClassificationId: subClassificationId });
    }

    locationAccount(accountId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.locationMapAccount), true, { accountId: accountId });
    }

    locationAccountDynamic(type: string, value: string, ipConfig: boolean) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.locationMapAccountDynamic), true, { type, value, ipConfig });
    }

    fetchSupportAndAdminUsersAssignee() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchAdminAndSupportAssignee), true);
    }

    activePatients(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.activePatients), true, { searchParam: searchParam });
    }
    registeredPatients(searchParam: string, fromDate: string, toDate: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.registeredPatients), true, { searchParam: searchParam, fromDate: fromDate, toDate: toDate });
    }

    providerSpecializations(providerId: number, encryptedProviderId?: string, locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.providerSpecializations), true, { providerId: providerId, encryptedProviderId: encryptedProviderId, locationId: locationId });
    }
    locationProviders(locationId?: number, encryptedProviderId?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.locationProviders), true, { locationId: locationId, encryptedProviderId: encryptedProviderId })
    }
    inventoryProdcut(id?: number, searchTerm: string = null) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.inventoryProduct), true, { categoryId: id, searchTerm: searchTerm });
    }

    notificationTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.notificationTypes))
    }

    payCategories() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.payCategories))
    }

    consultationType() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.consultationType));
    }

    availabilityChargeType(chargeId?: number, providerAvailabilitySlotId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.availabilityChargeType), true, { chargeId: chargeId, providerAvailabilitySlotId: providerAvailabilitySlotId });
    }

    providerAvailability(providerId: number, specializationId: number, consultationTypeId: number, locationId: number, appointmentDate?: string) {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.providerAvailability), { providerId: providerId, specializationId: specializationId, consultationTypeId: consultationTypeId, locationId: locationId, appointmentDate: appointmentDate });
    }

    receiptAreaTypeId() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.receiptAreaTypeId));
    }
    receiptAreaTypeIds() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.receiptAreaTypeIds));
    }

    activeStatus() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.activeStatus));
    }
    partsOfDay(providerId: number, specializationId?: number, locationId?: number, startDate?: string, endDate?: string) {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.partsOfDay), { providerId: providerId, specializationId: specializationId, locationId: locationId, startDate: startDate, endDate: endDate });
    }

    encounterType() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.encounterType), true);
    }

    encounterOrderTemplates() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.encounterOrderTemplates), true);
    }

    locationScanTest(locationId?: number, scanMachineMasterId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.locationScanTest), true, { locationId: locationId, scanMachineMasterId: scanMachineMasterId })
    }

    locationScanMachine(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.locationScanMachine), true, { locationId: locationId })
    }

    scanMachinesTests(scanTestMasterId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchScanMachinesTests), true, { scanTestMasterId: scanTestMasterId });
    }

    categoryType(scanTestMasterId?: number, locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchScanTestCategoryTypes), true, { scanTestMasterId: scanTestMasterId, locationId: locationId });
    }
    fetchRooms() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchRooms), true);
    }
    caseTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchCaseTypes), true);
    }
    emergencyCaseTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchEmergencyCaseTypes), true);
    }

    counsellingTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.counsellingTypes), true);
    }

    admissionPayTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchAdmissionPayTypes), true);
    }

    checkNewPatientAppointment(patientId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.checkNewPatientAppointment), true, { patientId: patientId });
    }

    referralDotor() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.referralDoctor), true);
    }

    doctorWeek() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.doctorWeek));
    }
    consultatDoctors(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.consultantDoctor), true, { searchParam: searchParam });
    }

    menuAccessedRoles(currentUrl: string) {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.menuAccessedRoles), { url: currentUrl });
    }

    pathologyProviders() {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.pathologyProviders));
    }

    scanAvailabilityStatus() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.scanAvailabilityStatus));
    }

    scanAvailabilityReason() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.scanAvailabilityReason));
    }

    providerBreak() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.providerBreak));
    }

    providerBlock() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.providerBlock));
    }

    visitType(visitTypeFor: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.visitType), true, { visitTypeFor: visitTypeFor });
    }

    scanScrollResult() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.scanScrollResult));
    }

    scanDoctors(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.scanDoctors), true, { locationId: locationId });
    }

    chargeCategory() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.chargeCategory), true);
    }

    locationsForPractice(practiceId?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.locationForPractice), true, { practiceId: practiceId });
    }
    practiceLocationsForProvider(providerId: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.practiceLocationsForProvider), true, { providerId: providerId });
    }
    chargeCategoryForLocations(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.chargeCategoryLocation), true, { locationId: locationId });
    }
    otRoomMaster() {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.otRoomMaster))
    }
    surgeryNames() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.surgeryNames), true);
    }
    providersForOt() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.providersForOt), true);
    }
    getAllAccountsExpectRoleId(id: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.accountWithExpectedRole), true, { roleId: id })
    }
    anaesthesiaTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.anaesthesiaTypes), true);
    }
    idProofNames() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.idProofNames), true);
    }

    locationOTSurgeris(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.locationSurgeries), true, { locationId: locationId })
    }

    surgeris() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.surgeris), true);
    }
    otRoomSurgeryTest(surgeryId?: number, otRoomId?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchOTRoomSurgery), true, { surgeryId: surgeryId, otRoomId: otRoomId });
    }

    discountNames() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.discountNames), true);
    }
    SessionType() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.SessionType), true);
    }
    inPatients(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.inPatients), true, { searchParam: searchParam });
    }

    visitTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.visitTypes));
    }
    uniqueOTRooms(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.uniqueOTRooms), true, { locationId: locationId })
    }
    healthCard() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.healthCard))
    }
    surgeries(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchSurgeries), true, { locationId: locationId })
    }

    ambulances() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.ambulances));
    }
    driversDetails() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.drivers));
    }

    reasons() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.reasons));
    }

    authority() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.authorityMaster));
    }

    doctorUnit(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.doctorUnit), true, { locationId: locationId });
    }

    providerSignature() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.providerSignature));
    }

    providerAccountLocationMap(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.providerAccountLocationMap), true, { locationId: locationId });
    }

    doctorUnitMasterId(admissionId: number, providerId?: number, specializationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.doctorUnitMasterId), true, { admissionId: admissionId, providerId: providerId, specializationId: specializationId });
    }

    scanProviderLocationBased(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.scanProviderLocationBased), true, { locationId: locationId });
    }

    medicationFrequency(type?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.medicationFrequency), true, { type: type });
    }

    lookUpValueOnLookUpName(name?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.lookupValueOnLookupName), true, { name: name });
    }

    providerAvailableLocations(encryptedProviderId?: string, locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchProviderLocation), true, { encryptedProviderId: encryptedProviderId, locationId: locationId });
    }

    scanDistinctOutPatients(fromDate: string, toDate: string, locationId: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.scanDistinctOutPatients), true, { fromDate: fromDate, toDate: toDate, locationId: locationId });
    }

    scanBulkRCPatients(fromDate: string, toDate: string, locationId: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.scanBulkRCPatients), true, { fromDate: fromDate, toDate: toDate, locationId: locationId });
    }

    dietGuidlines(typeId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.dietGuidlines), true, { typeId: typeId });
    }

    packageModules(locationId?: number, packageType?: string, payType?: string, chargeModuleTemplateId?: number): Observable<IResource[]> {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.packageModules), true, { locationId, packageType, payType, chargeModuleTemplateId });
    }

    packageTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.packageTypes));
    }

    moduleTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.moduleTypes));
    }
    dietTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.dietTypes));
    }

    doctorAppointmentNotice(locationId?: number, providerId?: number, appointmentDate?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.doctorAppointmentNotice), true, { locationId: locationId, providerId: providerId, appointmentDate: appointmentDate });
    }

    scanAppointmentNotice(locationId?: number, machineId?: number, appointmentDate?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.scanAppointmentNotice), true, { locationId: locationId, machineId: machineId, appointmentDate: appointmentDate });
    }

    emergencyDetail() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.emergencyDetails), true)
    }

    patientRegistrationCharges(locationId: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.patientRegistrationCharges), true, { locationId: locationId });
    }
    practice() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.practice), true);
    }

    encounterLookUp() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.enounterLookUp), true);
    }

    encounterLookupvalues(id?: number, encounterLookupName?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.encounterAllLookupvalues), true, { lookupId: id, lookupName: encounterLookupName });
    }

    scantestMachines(locationId?: number, scanTestMasterId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.scantestMachines), true, { locationId: locationId, scanTestMasterId: scanTestMasterId });
    }


    admissionPatient(searchParam: string, locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.admissionPatients), true, { searchParam: searchParam, locationId: locationId });
    }
    admissionActivePatient() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.admissionActivePatient), true);
    }
    parentPatient(parentPatietId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.parentPatient), true, { parentPatientId: parentPatietId });
    }
    lWareHouses(id: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.lWareHouses), true, { id: id });

    }
    retailStoreWare(wId: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.retailStoreWare), true, { wId: wId });
    }
    encounterTemplates() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.encounterTemplates), true);
    }
    modulesMaster() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.modulesMaster));
    }

    providersLocation(departmentId?: number, locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.providersLocation), true, { departmentId: departmentId, locationId: locationId });
    }
    allLabs() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchAllLabs), true);
    }
    providerBasedOnSpecialization(specializatonId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.providersBasedOnSpecialization), true, { specializationId: specializatonId });
    }

    locationChargeTemplate(admissionPayTypeId?: number) {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.locationChargeTemplate), { admissionPayTypeId: admissionPayTypeId });
    }

    chargeModuleTemplates(locationId?: number, payType?: string) {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.chargeTemplates), { locationId, payType });
    }
    dietConditionTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.dietConditionTypes));
    }

    dietConditions(dietTypeId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.dietConditions), true, { dietTypeId: dietTypeId });
    }

    allergies() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.allergies), true);
    }

    findPatient(patientId: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.patients), true, { patientId: patientId });
    }
    allpatientswithUmrandMobile(searchParam?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.allpatientswithUmrandMobile), true, { searchParam: searchParam });

    }
    fetchRoleDepartment() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchRoleDepartment));
    }

    products() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.products), true, { searchParam: "" });
    }

    productsFetch(searchParam?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.products), true, { searchParam: searchParam });
    }

    nationality() {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.nationality));
    }
    religion() {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.religion));
    }
    gender() {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.gender));
    }
    maritalStatus() {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.maritalStatus));
    }

    counsellingNumbers(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchCounsellingNumbers), true, { searchParam: searchParam });
    }

    inPatientsWithUmrAndMobile(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.inPatientsWithUmrAndMobile), true, { searchParam: searchParam });
    }
    roomWiseChargeCategory(roomId: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchRoomChargeCategory), true, { roomId: roomId });
    }
    fetchNonTransitRoomBeds(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchNonTransitRoomBeds), true, { locationId: locationId });
    }


    admissions(locationId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchLocationAdmissions), true, { locationId: locationId });
    }
    dietPlanTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.dietPlanTypes));

    }

    fetchICDValues(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchICDValues), true, { searchParam: searchParam });
    }
    cancelAdmissionReport(searchParam?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.cancelAdmissionPatient), true, { searchParam: searchParam });
    }
    fetchDietSlotsNames(searchParam?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.dietSlotvalueOnDietSlotname), true, { searchParam: searchParam });
    }
    fetchAdmissionReferralDoctor(searchParam?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.admissionReferralDoctor), true, { searchParam: searchParam });
    }

    fetchDoctorSpecializations(providerId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.doctorSpecializations), true, { providerId: providerId })
    }
    patientsForLabs(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.patientsForLabs), true, { searchParam: searchParam });
    }

    admissionNoForFinalBill() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.admissionNoForCancelledFinalBill));
    }

    billNumberForFinalBill() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.billNumberForCancelledFinalBill));
    }
    patientLogTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.patientLogTypes));
    }

    dailyBasesOutPatients(searchParam: string, fromDate: string, toDate: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.dailyBasesOutPatients), true, { searchParam: searchParam, fromDate: fromDate, toDate: toDate });
    }

    bookingVisitsPatients(searchParam: string, fromDate: string, toDate: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.bookingVisitsPatients), true, { searchParam: searchParam, fromDate: fromDate, toDate: toDate });
    }

    specializationUnit(locationId?, consultationTypeId?, specializationId?) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.specializationUnit), true, { locationId: locationId, consultationTypeId: consultationTypeId, specializationId: specializationId });
    }

    AdmissionNo() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchAdmissionNo), true);
    }

    fetchIssueNumber() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchIssueNumber), true);
    }
    fetchProviders() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.consultantNames), true);
    }
    fetchSpecializations() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.specializationNames), true);
    }

    tpa() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchTpa), true);
    }
    fetchCargeCategory() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchChargeCategoryNames), true);
    }
    fetchPatients() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchPatientNames), true);
    }
    fetchVisitTypeName() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchVisitTypeNames), true);
    }
    fetchDoctors() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchProvidernames), true);
    }
    consentForms() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchConsentForms), true);
    }
    service() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchServices), true)
    }

    reportName(searchParam?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchReportName), true, { reportName: searchParam });
    }
    fetchProviderMaster(locationId?: number) {
        return this.fetchWithLocation(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchProviderMaster), { locationId: locationId });
    }

    vendorNames(searchParam?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchVendorNames), true, { searchParam: searchParam });
    }
    fetchInsurancedetails(patientId?: number) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchInsuranceDetails), true, { patientId: patientId })
    }

    patientServicesLogTypes() {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.patientserviceslogType));
    }

    findencounterLookupvalues(id?: number, encounterLookupName?: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.findEncounterLookUpValues), true, { lookupId: id, lookupName: encounterLookupName });
    }

    imgUrl(imageName) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.defaulImgUrl), true, { imageName: imageName })
    }
    allCounsellingNumbers(searchParam: string) {
        return this.fetch(ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.fetchCounsellingAllNumbers), true, { searchParam: searchParam });
    }


}