<div class="card mb-0" *ngIf="loading">
    <div class="card-body p-2">
        <div class="d-flex align-items-center">
            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
            <span class="ml-2">Please wait while loading Counselling Details ...</span>
        </div>
    </div>
</div>
<div class="card mb-0" *ngIf="!loading && !counselling?.counsellingId">
    <div class="card-body p-2">
        <div class="mh-355 position-relative">
            <no-data [title]="'Counselling Details'"></no-data>
        </div>
    </div>
</div>
<div class="card mb-0" *ngIf="!loading && counselling?.counsellingId">
    <ng-container *ngIf="counsellingViewType !== viewType.ReadOnlyMode">
        <!--<div class="modify-counselling-patient badge font-weight-normal font-13 badge-info">
            <span [textContent]="counselling.patientName"></span>
            <span *ngIf="counselling.patientGender" [textContent]="'(' + counselling.patientGender + ')'"></span>
            <span class="mx-1">|</span>
            <span [textContent]="counselling.umrNo"></span>
        </div>-->
    </ng-container>
    <div class="card-body p-2">
        <!--<fieldset class="form-fieldset">
        <legend class="text-primary">Counselling Details</legend>
        <div class="row row-sm">
            <div class="col" [ngClass]="{'col-5' : counsellingViewType === viewType.ReadOnlyMode, 'col-4': counsellingViewType !== viewType.ReadOnlyMode}">
                <div class="info-box-row">
                    <div class="left">Counselling No</div>
                    <div class="right" [textContent]="counselling.counsellingNo"></div>
                </div>
                <div class="info-box-row">
                    <div class="left">Counselling Type</div>
                    <div class="right" [textContent]="counselling.counsellingType | counsellingType"></div>
                </div>
                <div class="info-box-row">
                    <div class="left">Pay Type</div>
                    <div class="right" [textContent]="counselling.payType | payType"></div>
                </div>
            </div>
            <div class="col" [ngClass]="{'col-7' : counsellingViewType === viewType.ReadOnlyMode, 'col-4': counsellingViewType !== viewType.ReadOnlyMode}">
                <div class="info-box-row">
                    <div class="left">Location</div>
                    <div class="right" [textContent]="counselling.locationName"></div>
                </div>
                <div class="info-box-row">
                    <div class="left">Charge Template</div>
                    <div class="right" [textContent]="counselling.templateName"></div>
                </div>
                <div class="info-box-row">
                    <div class="left">Insurance Company</div>
                    <div class="right" [textContent]="counselling.insuranceCompanyName || '-'"></div>
                </div>
            </div>
            <ng-container *ngIf="counsellingViewType !== viewType.ReadOnlyMode">
                <div class="col-4 col">
                    <div class="info-box-row">
                        <div class="left">TPA Organization</div>
                        <div class="right" [textContent]="counselling.tpaName || '-'"></div>
                    </div>
                    <div class="info-box-row">
                        <div class="left">Patient Organization</div>
                        <div class="right" [textContent]="counselling.patientOrganization || '-'"></div>
                    </div>
                    <div class="info-box-row">
                        <div class="left">Last Modified</div>
                        <div class="right" *ngIf="!counselling.modifiedByName">-</div>
                        <div class="right mw-62-p truncated" *ngIf="counselling.modifiedByName">
                            <span [textContent]="counselling.modifiedByName"></span>
                            <small class="text-mute ml-1" [textContent]="' / ' + (counselling.modifiedDate | amDateFormat : 'DD-MM-YYYY hh:mm a')"></small>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </fieldset>-->

        <fieldset class="form-fieldset" id="counselling_print">
            <legend class="text-primary">Counselling Details</legend>
            <div class="row row-sm">
                <div class="col-4 col">
                    <div class="info-box-row">
                        <div class="left">Patient Name</div>
                        <div>:</div>
                        <div class="right" [textContent]="counselling.patientName"></div>
                    </div>
                    <div class="info-box-row">
                        <div class="left">W/O</div>
                        <div *ngIf="husbandName">:</div>
                        <div class="right" [textContent]="husbandName"></div>
                    </div>
                    <div class="info-box-row">
                        <div class="left">Unit Name</div>
                        <div *ngIf="providerName">:</div>
                        <div class="right" [textContent]="providerName"></div>
                    </div>
                    <div class="info-box-row">
                        <div class="left">Counselling No</div>
                        <div *ngIf="counselling.counsellingNo">:</div>
                        <div class="right" [textContent]="counselling.counsellingNo"></div>
                    </div>
                    <div class="info-box-row">
                        <div class="left">Reimbursement</div>
                        <div>:</div>
                        <div class="right">
                            <input type="checkbox">
                            <label class="ml-1">YES</label>
                            &nbsp; &nbsp;
                            <input type="checkbox">
                            <label class="ml-1">NO</label>
                        </div>
                    </div>
                    <div class="info-box-row">
                        <div class="left">Counselling Type</div>
                        <div *ngIf="counselling.counsellingType">:</div>
                        <div class="right" [textContent]="counselling.counsellingType | counsellingType"></div>
                    </div>
                </div>
                <div class="col-4 col">
                    <div class="info-box-row">
                        <div class="left">UMR NO</div>
                        <div *ngIf="counselling.umrNo">:</div>
                        <div class="right" [textContent]="counselling.umrNo"></div>
                    </div>
                    <div class="info-box-row">
                        <div class="left">F/H</div>
                        <div *ngIf="counselling.relativeOccupation">:</div>
                        <div class="right" [textContent]="counselling.relativeOccupation"></div>
                    </div>
                    <div class="info-box-row">
                        <div class="left">Charge Template</div>
                        <div *ngIf="counselling.templateName">:</div>
                        <div class="right" [textContent]="counselling.templateName"></div>
                    </div>
                    <div class="info-box-row">
                        <div class="left">Pay Type</div>
                        <div *ngIf="counselling.payType ">:</div>
                        <div class="right" [textContent]="counselling.payType | payType"></div>
                    </div>
                    <div class="info-box-row">
                        <div class="left">Insurance Company</div>
                        <div *ngIf="counselling.insuranceCompanyName ">:</div>
                        <div class="right" [textContent]="counselling.insuranceCompanyName "></div>
                    </div>
                </div>
                <div class="col-4 col">
                    <div class="info-box-row">
                        <div class="left">Date Of Birth</div>
                        <div *ngIf="counselling.dateOfBirth ">:</div>
                        <div class="right" [textContent]="counselling.dateOfBirth | date: 'dd-MM-yyyy'"></div>
                    </div>
                    <div class="info-box-row" *ngIf="encounterType==='Gyn Card'">
                        <div class="left">DOS</div>
                        <div>
                            :
                        </div>

                    </div>
                    <div class="info-box-row" *ngIf="encounterType==='Anc Card'">
                        <div class="left">EDD</div>
                        <div>
                            :
                        </div>

                        <div class="right" [textContent]="eddDate | date: 'dd-MM-yyyy'"></div>
                    </div>
                    <div class="info-box-row" *ngIf="encounterType==='Anc Card' || encounterType==='Gyn Card'">
                        <div *ngIf="encounterType==='Anc Card'" class="left">ANC No</div>
                        <div *ngIf="encounterType==='Gyn Card'" class="left">GYN No</div>
                        <div *ngIf="encounterType==='Anc Card' || 'Gyn Card'">:</div>
                        <div class="right" [textContent]="ancNumber"></div>
                    </div>
                    <div class="info-box-row">
                        <div class="left">TPA Organization</div>
                        <div *ngIf="counselling.tpaName ">:</div>
                        <div class="right" [textContent]="counselling.tpaName "></div>
                    </div>
                    <div class="info-box-row">
                        <div class="left">Patient Organization</div>
                        <div *ngIf="counselling.patientOrganization ">:</div>
                        <div class="right" [textContent]="counselling.patientOrganization "></div>
                    </div>
                    <div class="info-box-row">
                        <div class="left">Last Modified</div>
                        <div class="right" *ngIf="counselling.modifiedByName">:</div>
                        <div class="right mw-62-p truncated" *ngIf="counselling.modifiedByName">
                            <span [textContent]="counselling.modifiedByName"></span>
                            <small [textContent]="' / ' + (counselling.modifiedDate | date : 'dd/MM/yyyy h:mm a')"></small>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
        <div class="table-responsive mt-2" *ngIf="counsellingPackages?.length">
            <table class="table table-sm table-centered table-striped table-bordered mb-0 font-13">
                <thead>
                    <tr>
                        <th>Package Name</th>
                        <th *ngIf="counsellingViewType !== viewType.ReadOnlyMode" style="width: 200px;">Charge Category</th>
                        <th *ngIf="counsellingViewType !== viewType.ReadOnlyMode" class="text-right" style="width: 120px;">Sub Total</th>
                        <th *ngIf="counsellingViewType !== viewType.ReadOnlyMode" class="text-right" style="width: 120px;">Package Discount</th>
                        <th *ngIf="counsellingViewType !== viewType.ReadOnlyMode" class="text-right" style="width: 120px;">Counselling Discount</th>
                        <th class="text-right" style="width: 120px;">Total</th>
                        <ng-container *ngIf="counsellingViewType !== viewType.ReadOnlyMode">
                            <th class="text-center" style="width: 80px;">In Use</th>
                            <th class="text-right" style="width: 50px;" class="text-right position-relative"></th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody class="font-13">
                    <tr [ngClass]="{'table-success': counsellingViewType === viewType.AddMode && item.isAdded}" *ngFor="let item of counsellingPackages; trackBy: trackByCounsellingPackage">
                        <td class="text-capitalize strke" [ngClass]="{'strike-item': item.strikeItem}" [textContent]="item.packageName"></td>
                        <td *ngIf="counsellingViewType !== viewType.ReadOnlyMode" [textContent]="item.chargeCategoryName" [ngClass]="{'strike-item': item.strikeItem}"></td>
                        <td *ngIf="counsellingViewType !== viewType.ReadOnlyMode" class="text-right" [ngClass]="{'strike-item': item.strikeItem}" [textContent]="item.subTotal | currency :'₹ ':'symbol':'1.2-2'"></td>
                        <td *ngIf="counsellingViewType !== viewType.ReadOnlyMode" class="text-right" [ngClass]="{'strike-item': item.strikeItem}" [textContent]="item.packageDiscount | currency :'₹ ':'symbol':'1.2-2'"></td>
                        <td *ngIf="counsellingViewType !== viewType.ReadOnlyMode" class="text-right" [ngClass]="{'strike-item': item.strikeItem}" [textContent]="item.discount | currency :'₹ ':'symbol':'1.2-2'"></td>
                        <td class="text-right" [ngClass]="{'strike-item': item.strikeItem}" [textContent]="item.total | currency :'₹ ':'symbol':'1.2-2'"></td>
                        <ng-container *ngIf="counsellingViewType !== viewType.ReadOnlyMode">
                            <td class="text-center" [ngClass]="{'strike-item': item.strikeItem}">
                                <i *ngIf="item.isInUse" class="mdi font-16 mdi-check-decagram text-success"></i>
                                <span *ngIf="!item.isInUse">-</span>
                            </td>
                            <td class="text-right">
                                <div class="d-flex align-items-center justify-content-end">
                                    <ng-container *ngIf="admitType === admitTypeEnum.All && !item.strikeItem">
                                        <a *ngIf="counsellingViewType === viewType.AddMode && item.isAdded" href="javascript:;" class="action-icon text-danger mr-1" (click)="togglePackage(item)" placement="left" ngbTooltip="Remove">
                                            <i class="mdi mdi-close-thick"></i>
                                        </a>
                                        <a *ngIf="counsellingViewType === viewType.AddMode && !item.isAdded" href="javascript:;" class="action-icon text-success mr-1" (click)="togglePackage(item)" placement="left" ngbTooltip="Add">
                                            <i class="mdi mdi-plus-thick"></i>
                                        </a>
                                    </ng-container>
                                    <a href="javascript:;" class="action-icon text-info" (click)="onViewCounsellingPackage(item, templateViewPackage)" placement="left" ngbTooltip="View Package"><i class="fe-eye"></i></a>
                                </div>
                            </td>
                        </ng-container>
                </tbody>
            </table>
        </div>
        <fieldset class="form-fieldset" *ngIf="counselling.extraExclusionsNotes">
            <legend class="text-primary font-17 text-center"> Exclusions Notes</legend>
            <div>
                <div style="white-space:normal; word-break:break-all;">
                    <span [innerHTML]="counselling.extraExclusionsNotes"></span>
                </div>
            </div>
        </fieldset>
        <ng-container *ngIf="counsellingViewType !== viewType.ReadOnlyMode">
            <div class="row row-sm mt-3">
                <div class="col-4 col">
                    <div class="form-group mb-0 d-flex flex-column">
                        <label class="font-13 font-weight-bold mb-0 text-primary">Counselling Given To</label>
                        <div class="d-flex font-13 align-items-center mb-1">
                            <p class="mb-0 font-weight-semibold text-capitalize">
                                <span class="text-uppercase" [textContent]="counselling.patientName"></span>
                            </p>
                            <small class="text-mute ml-1" [textContent]="'/ ' + counselling.umrNo"></small>
                        </div>
                        <div class="signature-container has-image no-hover" *ngIf="counselling.patientSignature">
                            <img class="img-fluid" alt="Patient Signature" [src]="page.getImage(counselling.patientSignatureRootPath + counselling.patientSignature)" />
                        </div>
                    </div>
                </div>
                <div class="col-4 col">
                    <div class="form-group mb-0 d-flex flex-column">
                        <label class="font-13 font-weight-bold mb-0 text-primary">Attendent</label>
                        <div class="signature-container has-image no-hover" *ngIf="counselling.attendentSignature">
                            <img class="img-fluid" alt="Attendent Signature" [src]="page.getImage(counselling.attendentSignatureRootPath + counselling.attendentSignature)" />
                        </div>
                    </div>
                </div>
                <div class="col-4 col">
                    <div class="form-group mb-0 d-flex flex-column align-items-end">
                        <label class="font-13 font-weight-bold mb-0 text-primary">Counsellor<small class="ml-1">(Counselling Given By)</small></label>
                        <div class="d-flex font-13 align-items-center mb-1">
                            <p class="mb-0 font-weight-semibold text-capitalize" [textContent]="counselling.createdByName"></p>
                            <small class="text-mute ml-1" [textContent]="' / ' + (counselling.createdDate | date : 'dd-MM-yyyy h:mm a')"></small>
                        </div>
                        <div class="signature-container has-image no-hover" *ngIf="counselling.counsellorSignature">
                            <img class="img-fluid" alt="Counsellor Signature" [src]="page.getImage(counselling.counsellorSignatureRootPath + counselling.counsellorSignature)" />
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="counselling.isDraft">
                <hr class="my-2" />
                <div class="d-flex align-items-center justify-content-end">
                    <button type="button" (click)="onSubmit()" [disabled]="isSubmitting" class="btn btn-sm mb-0 ml-1 btn-primary">
                        <span *ngIf="isSubmitting">
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            Please wait..
                        </span>
                        <span *ngIf="!isSubmitting">Publish</span>
                    </button>
                    <button type="button" (click)="onCancel()" [disabled]="isSubmitting" class="btn btn-sm mb-0 ml-1 btn-secondary">Cancel</button>
                </div>
            </ng-container>
            <ng-container *ngIf="counsellingViewType === viewType.AddMode">
                <hr class="my-2" />
                <div class="d-flex align-items-center justify-content-end">
                    <button type="button" (click)="onPackagesAdded()" [disabled]="isSubmitting" class="btn btn-sm mb-0 ml-1 btn-primary">
                        Submit
                    </button>
                    <button type="button" (click)="onCancel()" [disabled]="isSubmitting" class="btn btn-sm mb-0 ml-1 btn-secondary">Cancel</button>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
<ng-template #templateSignature>
    <div class="modal-header">
        <h4 class="modal-title">
            <span class="btn-group">
                <button type="button" class="btn btn-xs" [class.reset-btn]="signatureType === 'signature'" placement="left" ngbTooltip="Signature" [class.btn-outline-light]="signatureType === 'digital'" (click)="signatureType = 'signature'">Draw</button>
                <button type="button" class="btn btn-xs" [class.reset-btn]="signatureType === 'digital'" placement="left" ngbTooltip="Digital" [class.btn-outline-light]="signatureType === 'signature'" (click)="signatureType = 'digital'">Upload</button>
            </span>
            <span style="position: relative; top: 1px; left: 10px;">{{signatureFor}} Signature</span>
        </h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <div class="d-flex align-items-center" *ngIf="isSaveSignature">
            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
            <span class="ml-2">Please wait while saving uploaded signature...</span>
        </div>
        <img-crop *ngIf="signatureType === 'digital'" [image]="null" [aspectRatio]="4/4" [resizeToWidth]="250" (croppedImageEmitter)="onReceiveDrewSignature($event)"></img-crop>
        <signature-widget *ngIf="signatureType === 'signature'" width="500" height="200" (done)="onSignatureDone($event)"></signature-widget>
    </div>
    <div class="modal-footer" *ngIf="signatureType === 'digital'">
        <button type="button" class="btn btn-sm btn-secondary mr-1" (click)="onCloseModal();">Close</button>
        <button type="button" (click)="onCloseModal()" class="btn btn-sm btn-primary">Submit</button>
    </div>
</ng-template>
<ng-template #templateViewPackage>
    <div class="modal-header">
        <h4 class="modal-title">Package Details</h4>
        <button [useExistingCss]="true" printSectionId="invoiceId1" ngxPrint class="btn btn-light btn-xs mr-1 d-print-none">
            <i class="fe-printer mr-1"></i>Print
        </button>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div >
    <div class="modal-body" *ngIf="selectedCounsellingPackage" id="invoiceId1">
        <view-package-module [packageModuleId]="selectedCounsellingPackage.packageModuleId" [chargeCategory]="selectedCounsellingPackage.chargeCategory" [isCounselling]="true" [chargeCategoryId]="selectedCounsellingPackage.chargeCategoryId"></view-package-module>
    </div>
</ng-template>