export class OphthalmologyEncounterList {
      ophthalmologyEncounterId :number;
      appointmentId :number;
      active:boolean;
      createdBy :number;
      createdDate:Date
      modifiedDate:Date
      ophthalmicExamination:string
      patientId:number;
      isAdmission:boolean;
      modifiedBy:number;
      encryptedAppointmentId:number;
      ophthalmologyEncounterType:string
    jsonString: string; 
    orderPrescription: string;
    differentialDiagnosis: string;
    slitLapExamination: string;
    visualAcuityExamination: string;
    retinoscopy: string;
    externalExamination: string;
    postDelatedExamination: string;
    history: string;
}