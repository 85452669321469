import { LabParameterGroup } from "./lab-parameter-group.model";

export class LabParameterInputModel {
    labMainDetailId: number;
    testName: string;
    testCode: string;
    labDepartmentId: number;
    labSampleTypeId: number;
    active: boolean;
    departmentName: string;
    typeName: string;
    templates: Array<LabTemplateHeaderForInput>;
    encryptedNewLabBookingDetailId: string;
    constructor() {
        this.templates = new Array<LabTemplateHeaderForInput>();
    }
}

export class LabTemplateHeaderForInput {
    labTemplateHeaderId: number;
    labTemplateObservedValueId?: number;
    templateName: string;
    templateId: string;
    isMethod: boolean;
    methodText: string;
    isInterpretation: boolean;
    interpretationText: string;
    components: Array<LabComponentHeaderForInput>;
    parameters: Array<LabParameterInputHeaderModel>;

    constructor() {
        this.components = new Array<LabComponentHeaderForInput>();
        this.parameters = new Array<LabParameterInputHeaderModel>();
    }
}

export class LabComponentHeaderForInput {
    labComponentHeaderId: number;
    componentName: string;
    componentId: string;
    isShow: boolean;
    parameters: Array<LabParameterInputHeaderModel>;

    constructor() {
        this.parameters = new Array<LabParameterInputHeaderModel>();
    }
}

export class LabParameterInputHeaderModel {
    labParameterHeaderId: number;
    labParameterObservedValueId?: number;
    parameterName: string;
    displayName: string;
    referenceOutput: string;
    labParameterMethodId: number;
    labParameterDetailId?: number;
    methodName: string;
    observedValue: string;
    text: string;
    displayOrder?: number;
    allParameterDetail: Array<LabParameterGroup>;
    antibiotics: Array<LabParameterInputHeaderModel>;
    selected: LabParameterGroup;
    active?: true;
    showText: string;
    isBold: boolean;
    newLabBookingDetailId: number;
    type: string;
    defaultValue: string;
    antibioticName: string;
    labAntibioticsId?: number;
    antibioticsCount?: number;
    isHaveobservedValue: boolean;
    validation?: boolean;
    orgasms: Array<any>;
    formula?: string;
    parameterId?: string;
    observedValueList: Array<string>;
    isObservedValuePower: boolean;
    constructor() {
        this.allParameterDetail = new Array<LabParameterGroup>();
        this.antibiotics = new Array<LabParameterInputHeaderModel>();
        this.orgasms = new Array<any>();
        this.observedValueList = new Array<string>();

    }
}